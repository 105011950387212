@font-face {
  font-family: 'Apax Light';
  font-weight: 100;
  src: url('https://cdn.zuto.cloud/assets/fonts/ApaxThree-Light.woff2');
  format: ('woff2');
}

@font-face {
  font-family: 'Apax Regular';
  font-weight: 400;
  src: url('https://cdn.zuto.cloud/assets/fonts/ApaxThree-Regular.woff2');
  format: ('woff2');
}

@font-face {
  font-family: 'Apax Medium';
  font-weight: 500;
  src: url('https://cdn.zuto.cloud/assets/fonts/ApaxThree-Medium.woff2');
  format: ('woff2');
}

@font-face {
  font-family: 'Apax Bold';
  font-weight: 600;
  src: url('https://cdn.zuto.cloud/assets/fonts/ApaxThree-Bold.woff2');
  format: ('woff2');
}
